<template>
    <div class="modal-auto-text">
        <div class="head flex-row flex-between items-start">
            <div class="title" v-html="'스토리 수정하기'" />
        </div>
        <div class="top" />
        <div class="flex-row">
            <div class="label flex-wrap" v-html="$translate('CONTENT')" />
            <div class="textarea-wrapper p-relative">
                <textarea
                    :placeholder="$translate('PLACEHOLDER_ADD_TEMPLATE_CONTENT')"
                    v-model="text"
                    maxlength="1000"
                />
                <transition name="slide-up">
                    <Emoji
                        v-if="showEmoji"
                        class="emoji-wrapper p-absolute"
                        @onClickEmoji="onClickEmoji"
                        @close="showEmoji = false"
                    />
                </transition>
                <img
                    @click="showEmoji = !showEmoji"
                    class="auto-texts-function-icon"
                    @mouseover="overEmoji = true"
                    @mouseleave="overEmoji = false"
                    :src="emojiSrc"
                />
            </div>
        </div>
        <div class="buttons bottom">
            <div class="flex-row">
                <button @click="$emit('close', 0)" class="btn-default" v-html="$translate('CANCEL')" />
                <button
                    ref="confirm"
                    @click="onConfirm"
                    :class="disableConfirm ? 'disabled' : 'btn-primary'"
                    v-html="confirmLabel"
                />
            </div>
        </div>
    </div>
</template>
<script>
import userPersonalStoriesService from '@/services/user-personal-story'

export default {
    name: 'ModalEditUserPersonalStory',
    props: ['options'],
    data: () => ({
        text: null,
        titleFocused: false,
        showEmoji: false,
        overEmoji: false,
    }),
    computed: {
        disableConfirm() {
            return !this.text
        },
        title() {
            if (!this.options) return this.$translate('ADD_TEMPLATE')

            return this.$translate('EDIT_TEMPLATE')
        },
        confirmLabel() {
            if (!this.options) return this.$translate('ADD')

            return this.$translate('EDIT')
        },
        emojiSrc() {
            if (this.showEmoji) {
                return require(`@/assets/images/emoji_active.svg`)
            } else if (this.overEmoji) {
                return require(`@/assets/images/emoji_hover.svg`)
            } else {
                return require(`@/assets/images/emoji_inactive.svg`)
            }
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const dom = this.$refs.title
            if (dom) {
                dom.focus()
            }

            this.text = this.options.content
        },
        async update() {
            const payload = {
                ups_id: this.options.personalStoryId,
                user_id: this.options.userId,
                content: this.text
            }
            try {
                await userPersonalStoriesService.update(payload)
                this.$toast.success('수정되었습니다')
                this.$emit('close', 1)
            } catch (e) {
                // this.$toast.error(e.data)
                // console.log(e)
            }
        },
        onConfirm() {
            if (this.disableConfirm) {
                const dom = this.$refs.confirm
                if (dom) {
                    this.$animate.shake(dom)
                }
                return
            }

            this.update()
        },
        onClickEmoji(value) {
            this.showEmoji = false
            if (!this.text.content) this.text.content = ''
            this.text.content += value

            if (this.dom) this.dom.focus()
        },
    },
}
</script>
